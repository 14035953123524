import React, { createContext, useState } from 'react';

export const GeoLocationContext = createContext(null);

export const GeoLocationProvider = ({ children, settings }) => {
  let [location, setLocation] = useState('');
  let [phoneNumber, setPhoneNumber] = useState('+123456789');

  const updateLocation = (locationValue) => {
    setLocation(locationValue);
  };

  return (
    <GeoLocationContext.Provider value={{ location, updateLocation, phoneNumber }}>
      {children}
    </GeoLocationContext.Provider>
  );
};
